import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Theme } from '@mui/material/styles'

import {
  setAppLayout,
  setEditLayout,
  setPrefersDarkMode,
  setTheme,
  setCrudeAppName, addMessage, setUser, setLogo,
} from './settings.actions'
import { createTheme } from '@mui/material'

import { Message } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

export interface SettingsState {
  prefersDarkMode: boolean
  editLayout?: any
  appLayout?: any
  theme: Theme
  logo?: any
  crudeAppName?: string
  message?: Message
  user?: any
}

const INITIAL_SETTINGS_STATE: SettingsState = {
  prefersDarkMode: localStorage.getItem('darkMode') === 'true',
  theme: createTheme(),
  crudeAppName: 'demo',
  logo: localStorage.getItem('darkMode') === 'true' ? '/logoDeviDark.png' : '/logoDeviLight.png',
  user: {
    id: 1,
  },
}

export const settingsStateReducer = reducerWithInitialState(INITIAL_SETTINGS_STATE)
  .case(setPrefersDarkMode, (state, prefersDarkMode) => {
    return ({ ...state, prefersDarkMode })
  })
  .case(setEditLayout, (state, editLayout) => {
    return ({ ...state, editLayout })
  })
  .case(setAppLayout, (state, appLayout) => {
    return ({ ...state, appLayout })
  })
  .case(setTheme, (state, theme) => {
    return ({ ...state, theme })
  })
  .case(setCrudeAppName, (state, crudeAppName) => {
    return ({ ...state, crudeAppName })
  })
  .case(addMessage, (state: SettingsState, message: Message) => {
    return ({ ...state, message })
  })
  .case(setUser, (state: SettingsState, user: any) => {
    return ({ ...state, user })
  })
  .case(setLogo, (state: SettingsState, logo: string) => {
    return ({ ...state, logo })
  })
